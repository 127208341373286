import React, { useState, useEffect } from "react";
import { FiSearch, FiMoon, FiSun } from "react-icons/fi";
import { format } from "date-fns";

const BlogList = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [visiblePosts, setVisiblePosts] = useState(6);

  const blogPosts = [
    {
      id: "1",
      title: "Learn React & Webpack by building the Hacker News front page",
      author: "FeanLau",
      date: "2025-02-10",
      excerpt:
        "这是一篇给初学者的教程, 在这篇教程中我们将通过构建一个 Hacker News 的前端页面来学习 React 与 Webpack. 它不会覆盖所有的技术细节, 因此它不会使一个初学者变成大师, 但希望能给初学者一个大致印象.",
      imageUrl: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6",
      category: "React",
      link: "learn-react-webpack-by-building-the-hacker-news-front-page",
    },
  ];

  const categories = [...new Set(blogPosts.map((post) => post.category))];

  const filteredPosts = blogPosts.filter((post) => {
    const matchesSearch =
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategory === "all" || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const loadMore = () => {
    setVisiblePosts((prev) => prev + 3);
  };

  const BlogPostCard = ({ post }) => (
    <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:transform hover:scale-105">
      <div className="relative h-48 overflow-hidden">
        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-full object-cover"
          loading="lazy"
          onError={(e) => {
            e.target.src =
              "https://images.unsplash.com/photo-1516259762381-22954d7d3ad2";
          }}
        />
      </div>
      <div className="p-6">
        <div className="text-sm text-blue-600 dark:text-blue-400 mb-2">
          {post.category}
        </div>
        <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">
          {post.title}
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">{post.excerpt}</p>
        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-500 dark:text-gray-400">
            <span>{post.author}</span>
            <span className="mx-2">•</span>
            <span>{format(new Date(post.date), "MMM dd, yyyy")}</span>
          </div>
          <a href={post.link}>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors">
              Read More
            </button>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`min-h-screen ${darkMode ? "dark" : ""}`}>
      <div className="bg-gray-100 dark:bg-gray-900 py-8 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              Latest Blog Posts
            </h1>
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {darkMode ? (
                <FiSun className="w-6 h-6 text-white" />
              ) : (
                <FiMoon className="w-6 h-6 text-gray-900" />
              )}
            </button>
          </div>

          <div className="mb-8 space-y-4 md:space-y-0 md:flex md:items-center md:space-x-4">
            <div className="relative flex-1">
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search posts..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <select
              className="w-full md:w-48 p-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">All Categories</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.slice(0, visiblePosts).map((post) => (
              <BlogPostCard key={post.id} post={post} />
            ))}
          </div>

          {visiblePosts < filteredPosts.length && (
            <div className="text-center mt-8">
              <button
                onClick={loadMore}
                className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
