import React from "react";

function ChangeLog() {
  return (
    <div>
      <h1>更新日志</h1>

      <div>
        <ul>
          <li>2024-09-11 : Html页面的格式化</li>
        </ul>
        <ul>
          <li>2024-07-20 : 修复成功在本地运行的问题</li>
        </ul>
        <ul>
          <li>2024-05-16 : Html静态站点细节逐步优化</li>
        </ul>
        <ul>
          <li>
            2024-05-15 : WordPress to html，用Simply Static插件生成静态站点
          </li>
        </ul>
        <ul>
          <li>2024-04-25 : 修复文章细节问题，恢复Github超链接</li>
        </ul>

        <ul>
          <li>2024-04-13 : WordPress在MacOS编辑，修复文章细节问题</li>
        </ul>

        <ul>
          <li>2024-04-11 : WordPress升级到6.5.2，网站footer链接更新</li>
        </ul>

        <ul>
          <li>2024-02-27 : 文章增加Table Of Content，适合mobile端</li>
        </ul>

        <ul>
          <li>2024-02-24 : 弃用mega menu插件，改用Twenty Twenty-Four主题</li>
        </ul>

        <ul>
          <li>2024-01-24 : “文史哲”栏目改名为“随记”栏目</li>
        </ul>

        <ul>
          <li>2024-01-06 : 站点sitemap地图生成，将中文全部替换为英文路径</li>
        </ul>

        <ul>
          <li>
            2024-01-02 :
            添加&#8221;标签云&#8221;页面；菜单调整为类目的“编程”子菜单；添加SQL
            diff功能，并导入备份
          </li>
        </ul>

        <ul>
          <li>2023-12-30 : menu菜单样式调整，增加分页页码区块</li>
        </ul>

        <ul>
          <li>2023-12-27 : 调整header和footer部分，重新设计</li>
        </ul>

        <ul>
          <li>2023-12-25 : 采用mega menu插件，重新设置菜单</li>
        </ul>

        <ul>
          <li>
            2023-12-24 : 清空
            <a
              href="https://my.oschina.net/"
              target="_blank"
              rel="noreferrer noopener"
            >
              oschina.net
            </a>
            博客文章，迁移完毕
          </li>
        </ul>

        <ul>
          <li>2023-12-23 : 清空csdn.net博客文章</li>
        </ul>

        <ul>
          <li>2023-12-22 : 添加代码高亮插件</li>
        </ul>

        <ul>
          <li>2023-12-21 : 替换favicon；增加我的微博账号</li>
        </ul>

        <ul>
          <li>
            2023-12-19 : 本地上传至腾讯云服务器；配置Google
            Analytics(GA)统计；从
            <a
              href="https://my.oschina.net/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://my.oschina.net/
            </a>
            迁移文章至feanlau.com
          </li>
        </ul>

        <ul>
          <li>
            2023-12-17 : 从
            <a
              href="https://my.oschina.net/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://my.oschina.net/
            </a>
            迁移文章至feanlau.com
          </li>
        </ul>

        <ul>
          <li>
            2023-12-16 : 从
            <a
              href="https://my.oschina.net/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://my.oschina.net/
            </a>
            迁移文章至feanlau.com
          </li>
        </ul>

        <ul>
          <li>
            2023-12-15 : 从
            <a
              href="https://my.oschina.net/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://my.oschina.net/
            </a>
            迁移文章至feanlau.com，目前文章数量新增10
          </li>
        </ul>

        <ul>
          <li>
            2023-12-11：feanlau.com域名在腾讯云备案管局审核通过，部署在腾讯云服务器上，网站可以对外访问
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ChangeLog;
