import React from "react";
import avatar from "../assests/avatar.jpg";

function About() {
  return (
    <div>
      {/* Main Content */}
      <main className="mx-auto max-w-2xl px-6 py-12">
        <h1 className="mb-12 text-center text-4xl font-bold">关于我</h1>

        <div className="flex flex-col items-center space-y-6">
          <div className="relative h-64 w-64">
            <img
              src={avatar}
              alt="Profile Image"
              fill
              className="rounded-lg object-cover"
              priority
            />
          </div>

          <p className="text-center">Fean Lau 取名的思路来自于 Andy Lau</p>

          <p className="text-center">关于我，你可以在其它社交媒体找我：</p>

          <div className="space-y-2">
            <p>
              WordPress：
              <a
                href="https://profiles.wordpress.org/feanlau/"
                className="text-blue-400 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://profiles.wordpress.org/feanlau/
              </a>
            </p>

            <p>
              邮箱：
              <a
                href="mailto:feanlau@outlook.com"
                className="text-blue-400 hover:underline"
              >
                feanlau@outlook.com
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default About;
