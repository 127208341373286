import React from "react";
import logo from "../assests/logo_light.svg";

function Footer() {
  return (
    <div>
      {/* <!-- footer --> */}
      <footer class="border-t mt-12 pt-12 pb-32 px-4 lg:px-0">
        <div>
          <img src={logo} class="h-12 w-12" alt="logo" />
        </div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-2/5">
            <p class="text-gray-600 hidden lg:block mt-4 p-0 lg:pr-12">
              React RED - React Research Engineer Development
            </p>
          </div>

          <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
            <h6 class="font-semibold text-gray-700 mb-4">Company</h6>
            <ul>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Team
                </a>
              </li>
              <li>
                <a href="/about-me" class="block text-gray-600 py-2">
                  About us
                </a>
              </li>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Press
                </a>
              </li>
            </ul>
          </div>

          <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
            <h6 class="font-semibold text-gray-700 mb-4">Content</h6>
            <ul>
              <li>
                <a href="/blogs" class="block text-gray-600 py-2">
                  Blog
                </a>
              </li>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
            <h6 class="font-semibold text-gray-700 mb-4">Company</h6>
            <ul>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Team
                </a>
              </li>
              <li>
                <a href="/about-me" class="block text-gray-600 py-2">
                  About us
                </a>
              </li>
              <li>
                <a href="" class="block text-gray-600 py-2">
                  Press
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
