import React from "react";

function Posts() {
  return (
    <div>
      {/* <!-- component --> */}
      <div class="max-w-screen-lg mx-auto">
        <main class="mt-10">
          <div class="mb-4 md:mb-0 w-full mx-auto relative">
            <div class="px-4 lg:px-0">
              <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                Learn React & Webpack by building the Hacker News front page
              </h2>
              <a
                href="#"
                class="py-2 text-green-700 inline-flex items-center justify-center mb-2"
              >
                React
              </a>
            </div>

            <img
              src="https://images.unsplash.com/photo-1587614387466-0a72ca909e16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
              class="w-full object-cover lg:rounded"
            />
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <p class="pb-6">
                这是一篇给初学者的教程, 在这篇教程中我们将通过构建一个 Hacker
                News 的前端页面来学习 React 与 Webpack.
                它不会覆盖所有的技术细节, 因此它不会使一个初学者变成大师,
                但希望能给初学者一个大致印象.
                <div>
                  <strong>
                    注意: 这篇文章已经有部分过时,需要等待更新.文章写成时 webpack
                    版本使用的是 1.x,现在 webpack 默认安装 2.x.按照本篇的配置
                    webpack 将会报错，教程更新已在计划中
                  </strong>
                </div>
              </p>

              <p class="pb-6">
                Difficulty on insensible reasonable in. From as went he they.
                Preference themselves me as thoroughly partiality considered on
                in estimating. Middletons acceptance discovered projecting so is
                so or. In or attachment inquietude remarkably comparison at an.
                Is surrounded prosperous stimulated am me discretion expression.
                But truth being state can she china widow. Occasional preference
                fat remarkably now projecting uncommonly dissimilar. Sentiments
                projection particular companions interested do at my delightful.
                Listening newspaper in advantage frankness to concluded
                unwilling.
              </p>

              <p class="pb-6">
                Adieus except say barton put feebly favour him. Entreaties
                unpleasant sufficient few pianoforte discovered uncommonly ask.
                Morning cousins amongst in mr weather do neither. Warmth object
                matter course active law spring six. Pursuit showing tedious
                unknown winding see had man add. And park eyes too more him.
                Simple excuse active had son wholly coming number add. Though
                all excuse ladies rather regard assure yet. If feelings so
                prospect no as raptures quitting.
              </p>

              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                Sportsman do offending supported extremity breakfast by
                listening. Decisively advantages nor expression unpleasing she
                led met. Estate was tended ten boy nearer seemed. As so seeing
                latter he should thirty whence. Steepest speaking up attended it
                as. Made neat an on be gave show snug tore.
              </div>

              <p class="pb-6">
                Exquisite cordially mr happiness of neglected distrusts.
                Boisterous impossible unaffected he me everything. Is fine loud
                deal an rent open give. Find upon and sent spot song son eyes.
                Do endeavor he differed carriage is learning my graceful. Feel
                plan know is he like on pure. See burst found sir met think
                hopes are marry among. Delightful remarkably new assistance saw
                literature mrs favourable.
              </p>

              <h2 class="text-2xl text-gray-800 font-semibold mb-4 mt-4">
                Uneasy barton seeing remark happen his has
              </h2>

              <p class="pb-6">
                Guest it he tears aware as. Make my no cold of need. He been
                past in by my hard. Warmly thrown oh he common future. Otherwise
                concealed favourite frankness on be at dashwoods defective at.
                Sympathize interested simplicity at do projecting increasing
                terminated. As edward settle limits at in.
              </p>

              <p class="pb-6">
                Dashwood contempt on mr unlocked resolved provided of of.
                Stanhill wondered it it welcomed oh. Hundred no prudent he
                however smiling at an offence. If earnestly extremity he he
                propriety something admitting convinced ye. Pleasant in to
                although as if differed horrible. Mirth his quick its set front
                enjoy hoped had there. Who connection imprudence middletons too
                but increasing celebrated principles joy. Herself too improve
                gay winding ask expense are compact. New all paid few hard pure
                she.
              </p>

              <p class="pb-6">
                Breakfast agreeable incommode departure it an. By ignorant at on
                wondered relation. Enough at tastes really so cousin am of.
                Extensive therefore supported by extremity of contented. Is
                pursuit compact demesne invited elderly be. View him she roof
                tell her case has sigh. Moreover is possible he admitted
                sociable concerns. By in cold no less been sent hard hill.
              </p>

              <p class="pb-6">
                Detract yet delight written farther his general. If in so bred
                at dare rose lose good. Feel and make two real miss use easy.
                Celebrated delightful an especially increasing instrument am.
                Indulgence contrasted sufficient to unpleasant in in insensible
                favourable. Latter remark hunted enough vulgar say man. Sitting
                hearted on it without me.
              </p>
            </div>

            <div class="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div class="p-4 border-t border-b md:border md:rounded">
                <div class="flex py-2">
                  <img
                    src="https://randomuser.me/api/portraits/men/97.jpg"
                    class="h-10 w-10 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p class="font-semibold text-gray-700 text-sm">FeanLau</p>
                    <p class="font-semibold text-gray-600 text-xs"> Editor </p>
                  </div>
                </div>
                <p class="text-gray-700 py-3">
                  Fean writes about technology Yourself required no at thoughts
                  delicate landlord it be. Branched dashwood do is whatever it.
                </p>
                <button class="px-2 py-1 text-gray-100 bg-green-700 flex w-full items-center justify-center rounded">
                  Follow
                  <i class="bx bx-user-plus ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </main>
        {/* <!-- main ends here --> */}
      </div>
    </div>
  );
}

export default Posts;
